:root {
    --background-color: rgb(28, 32, 33);
    --link-color: rgb(123, 240, 255);
    --link-hovered-color: rgb(0, 112, 243);
    --danger-link-hovered-color: rgb(255, 112, 112);
    --error-color: rgb(255, 72, 72);
    --dialog-text-color: rgb(205, 205, 205);
    --dialog-cross-button-color: rgb(90, 90, 90);
    --dialog-cross-button-hovered-color: rgb(122, 122, 122);
    --input-field-background-color: rgb(59, 59, 59);
    --success-color: rgb(133, 255, 133);
    --continue-button-color: rgb(3, 187, 68);
    --continue-button-hovered-color: rgb(0, 148, 52);
    --continue-button-active-color: rgb(0, 109, 38);
    --cancel-button-color: rgb(244, 67, 54);
    --cancel-button-hovered-color: rgb(155, 43, 35);
    --cancel-button-active-color: rgb(87, 24, 20);
    --button-processing-color: rgb(0, 85, 30);
    --button-processing-color-hovered: rgb(0, 56, 20);
    --button-processing-color-active: rgb(0, 39, 14);
    --default-input-border-color: rgb(72, 78, 81);
    --footer-background-color: rgb(41, 41, 41);
    --uneven-sections-background-color: rgb(68, 74, 77);
    --main-section-background-color: rgb(51, 55, 56);
    --text-field-background-color: rgb(44, 44, 44);
    --text-field-border-color: rgb(126, 137, 141);
    --approved-test-background-color: rgba(36, 80, 48, 0.5);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, 'sans-serif';
}

html, body {
    padding: 0;
    margin: 0;
    background-color: var(--background-color);
}

a {
    color: inherit;
    text-decoration: none;
}

body.dialog-opened {
    overflow: hidden;
}


/* nprogress styles to appear over the navbar */

#nprogress {
    z-index: 99999;
    pointer-events: none;
}

#nprogress .bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #29d;
    z-index: 999999999;
  }

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
    display: block;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 9999999;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
